import React from 'react';
import styled from 'styled-components';
import Footer from '../components/common/footer';
import { AppearText } from '../components/common/appear';
import NewsItem from '../components/newsitem';

const Page = styled.div`
  min-height: 100vh;
  background: #FFF;
  padding-top: 241px;
  max-width: 1241px;
  margin: 0 auto;
  padding: 0 135px;
  padding-top: 241px;

  width: 100%;
  max-width: 1620px;
  @media (max-width: 1600px) {
    max-width: 1241px;
    padding: 0 104px;
    padding-top: 241px;
  }
  @media (max-width: 1200px) {
    padding: 0 57px;
    padding-top: 241px;
    min-height: auto;
  }

  @media (max-width: 600px) {
      padding: 0 15px;
      padding-top: 180px;
  }
`;
const FooterWrapper = styled.div`
    margin-top: 140px;
    padding-bottom: 60px;
    width: 100%;
    @media (max-width: 600px) {
        margin-top: 60px;
    }
`;
const NewsWrapper = styled.div`
    margin-top: 140px;
    padding-bottom: 60px;
    width: 100%;
    @media (max-width: 600px) {
        margin-top: 60px;
        padding-bottom: 10px;
    }
`;
const H1 = styled.h1`
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 92px;
    line-height: 80px;
    color: #0064BA;
    /* or 87% */
    @media (max-width: 1200px) {
        font-style: normal;
        font-weight: bold;
        font-size: 92px;
        line-height: 80px;
    }

    @media (max-width: 600px) {
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 54px;
    }
`;

const NewsPage = () => {

    return (
        <>
            <Page>
                <AppearText>
                    <H1>News</H1>
                </AppearText>
                <NewsWrapper>
                    {
                        Array(8).fill(0).map(() => {
                            return <NewsItem />;
                        })
                    }
                </NewsWrapper>
                
                <FooterWrapper>
                    <Footer noPadding />
                </FooterWrapper>
            </Page>
        </>
    )
}

export default NewsPage;
