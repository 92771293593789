import React from 'react';
import styled from 'styled-components';

export const NewsItemWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 25px;
    height: 343px;
    @media (max-width: 600px) {
        flex-wrap: wrap;
        height: auto;
        margin-bottom: 50px;
    }
`;

export const ColImage = styled.div`
    width: 398px;
    height: 343px;
    @media (max-width: 600px) {
        width: 100%;
        width: calc(100vw - 28px);
        height: 247px;
        margin-bottom: 20px;
    }
`;

export const ColText = styled.div`
    width: 50%;
    height: 343px;
    position: relative;
    @media (max-width: 600px) {
        width: 100%;
        height: auto;
    }
`;

export const H3 = styled.h3`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    color: #0064BA;
    margin-bottom: 0;
    @media (max-width: 1200px) {
        font-size: 28px;
        line-height: 34px;
    }
    @media (max-width: 600px) {
        font-size: 22px;
        line-height: 26px;
    }
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const ContentWrapper = styled.div`
    width: calc(100% - 100px);
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    @media (max-width: 1200px) {
        left: 30px;
        width: calc(100% - 40px);
    }
    @media (max-width: 600px) {
        position: relative;
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
        transform: none;
    }
`;

export default {
    NewsItemWrapper,
    ColImage,
    ColText,
    H3,
};
