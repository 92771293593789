import React from 'react';
import {
    NewsItemWrapper,
    ColImage,
    ColText,
    H3,
    ContentWrapper
} from './elements';
import { AppearImage, AppearText, } from '../common/appear';
import P from '../common/p';
import Link from '../common/customlink';

const NewsItem = ({ image = '', title = 'Maecenas faucibus mollis interdum. Maecenas faucibus mollis interdum', desc = 'Maecenas faucibus mollis interdum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus'}) => (
    <NewsItemWrapper>
        <AppearImage flex>
            
            <ColImage style={{ background: '#C4C4C4', }} />
        </AppearImage>
        <ColText>
            <ContentWrapper>
                <AppearText>
                    <Link to="/singlenews"><H3>{title}</H3></Link>
                </AppearText>
                <AppearText>
                    <P>{desc} (...)</P>
                </AppearText>
            </ContentWrapper>
        </ColText>
    </NewsItemWrapper>
)

export default NewsItem;
